import React, { memo } from "react";
import { images } from "src/assets/logos/logo";
import { FLEX_COL_CENTER, SHADES } from "src/styles/styleGuide";
import { envTenant } from "src/utils/tenantSettings";
import styled from "styled-components";

// Default colors according to tenant
export const brokersDefaultColors = {
  renew_buy: "#ff6600",
  fyntune: "#0a87ff",
  pinc: "#e1056d",
  uib: "#0067b1",
  sriyah: "#626dc7",
  spa: "#3877d6",
  analah: "#092c4c",
  bimaplanner: "#22ade3",
  choice: "#0063a6",
  bajaj: "#ed1c24",
  sib: "#3d3e81",
  policyera: "#3877d6",
  shree: "#f40000",
  tmibasl: "#4f78d6",
  iifl: "#e74c3c",
  radical: "#ff5500",
  hero: "#ed3237",
  vcare: "#2c44a1",
};

function FullScreenLoader() {
  function imageWidth(tenant) {
    switch (tenant) {
      case "choice":
        return "70%";
      case "hero":
        return "65%";
      default:
        return "100%";
    }
  }

  const tenantColor = brokersDefaultColors[envTenant()];
  const tenantLogo = images[envTenant()];
  return (
    <FullScreenLoaderContainerStyled
      aria-label="loading"
      tenantColor={tenantColor}
      imageWidth={imageWidth(envTenant())}
    >
      <div className="fullscreen_loader_content">
        <img src={tenantLogo} alt={`${envTenant() + "-logo"}`} loading="lazy" />
        <div className="fullscreen_loader_bar" />
      </div>
    </FullScreenLoaderContainerStyled>
  );
}

const FullScreenLoaderContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 1100;
  ${FLEX_COL_CENTER};

  .fullscreen_loader_content {
    width: 15rem;
    ${FLEX_COL_CENTER};
    gap: 1rem;

    img {
      width: ${props => props.imageWidth};
    }
  }
  .fullscreen_loader_bar {
    height: 4px;
    background-color: ${SHADES.WHITE};
    width: 100%;
    margin-top: 10px;
    border-radius: 100px;
    position: relative;
    overflow-x: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: ${props => props.tenantColor || SHADES.GRAY};
      animation: loading 3s infinite;
    }
  }
`;

export default memo(FullScreenLoader);
