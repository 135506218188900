import { memo } from "react";
import { RiLoader4Fill } from "react-icons/ri";
import { useTheme } from "src/customHooks";
import { useResponsiveCondition } from "src/pos/hooks";
import FHTypography from "src/styles/Typography";
import { DEVICES, VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW_CENTER, ROUNDED, SHADES } from "src/styles/styleGuide";
import styled from "styled-components";

export function Button({
  children,
  loader = false,
  disabled = false,
  onClick,
  loaderPrimaryColor = true,
  borderRadiusBottom = false,
  font,
  btnId = "continueCTA",
  loaderSize = 0,
  css: customCss,
  ...props
}) {
  const { colors } = useTheme();
  const { breakpoint, width } = useResponsiveCondition(DEVICES.tabletBIG);
  const fontVariant = font || "h6";

  const handleClick = evt => {
    if (loader || disabled) return;
    onClick && onClick(evt);
  };

  return (
    <ButtonStyled
      colors={colors}
      loaderPrimaryColor={loaderPrimaryColor}
      loader={loader}
      disabled={loader || disabled}
      onClick={handleClick}
      borderRadiusBottom={borderRadiusBottom}
      css={customCss}
      {...props}
    >
      <FHTypography
        variant={fontVariant}
        weight="md"
        colorType="white"
        id={`auto-${btnId}`}
        data-auto-test-script="tag-content"
      >
        {children}
      </FHTypography>
      {loader && (
        <RiLoader4Fill
          className="rotate"
          size={loaderSize ? loaderSize : width > breakpoint ? 25 : 20}
        />
      )}
    </ButtonStyled>
  );
}

export const MemoizedButton = memo(Button);

const ButtonStyled = styled.button`
  background: ${props => props.colors.primary_color};
  border: none;
  border-radius: ${ROUNDED.lg};
  min-width: max-content;
  width: ${props => props.width};
  padding: 0.7rem;
  cursor: pointer;
  ${FLEX_ROW_CENTER};
  gap: 0.5rem;
  transition: all 0.2s ease;
  color: ${SHADES.WHITE};
  ${props => props.css}
  &:hover:not(:disabled) {
    filter: brightness(0.9);
  }
  &:disabled {
    cursor: default;
    opacity: 0.8;
  }
  .retry_btn {
    border-radius: 0.5rem;
    min-width: 120px;
  }

  ${VIEW.md} {
    padding: 1rem;
  }
`;
