function removeHtmlTagsFromString(str) {
  return str.replace(/<[^>]+>/g, "");
}

function removeSpaceAddUnderscore(str = "") {
  str = str.toLowerCase();
  return str.replace(/ /g, "_");
}

// remove Numerics from end of a string
function cutLastNumericValue(s) {
  while (s?.length > 0 && !isNaN(s[s.length - 1]) && s[s.length - 1] !== " ") {
    s = s.substring(0, s.length - 1);
  }
  return s;
}

// function that convert age into year and month
const ageStringToYear = (ageString = "") => {
  if (ageString === "Below 3 months") {
    return 0;
  }

  if (ageString.toLowerCase().includes("month")) {
    return (ageString.split(" ")[0] / 12).toFixed(2);
  } else {
    return ageString.split(" ")[0];
  }
};

// Replaces the string after {allowedLength} parameter with "..."
const stringLengthCutter = (str, allowedLength) => {
  if (str?.length > allowedLength) {
    return `${str.substring(0, allowedLength + 1)}...`;
  }
  return str;
};

// add "₹" and "," symbols according to the amount
function amount(number = 0) {
  return `₹ ${parseInt(number).toLocaleString("en-In")}`;
}

const effectiveStringCutter = ({
  string = "Hey i am Dummy string there",
  approxLength = 12,
  hardCut = false,
  endOfStringAs = "dotted/space/none/star",
}) => {
  //? Working
  //* This methods returns a string in such a way that no word is break in between.
  //* In case hardCut is true, than no such above checks will be applied and result given.
  //* General End of string Decoration is provided by endOfStringAs.

  const endOfStringConfig = {
    dotted: string => {
      return `${string}..`;
    },
    space: string => {
      return `${string} `;
    },
    star: string => {
      return `${string}***`;
    },
    none: string => {
      return string;
    },
  };

  if (approxLength > string.length) {
    return string;
  }

  if (hardCut) {
    const hardCutString = string.slice(0, approxLength + 1);
    if (endOfStringAs) {
      return (
        endOfStringConfig?.[endOfStringAs]?.(hardCutString) ||
        "Not a valid end of string value."
      );
    }
    return hardCutString;
  } else {
    const nearBySpaceCharacter =
      string
        .slice(approxLength + 1)
        .split("")
        .findIndex(element => element === " ") +
      approxLength +
      1;
    const effectiveCutString = string.slice(0, nearBySpaceCharacter);
    if (endOfStringAs) {
      return (
        endOfStringConfig?.[endOfStringAs]?.(effectiveCutString) ||
        "Not a valid end of string value."
      );
    }
    return effectiveCutString;
  }
};

// returning a hard coded string for single children
// for multiple son or daughter return code along with their number
const getMemberName = ({
  memberCode = "self",
  isSingleSon = false,
  isSingleDaughter = false,
}) => {
  if (memberCode.startsWith("son") && isSingleSon) {
    return "son";
  }
  if (memberCode.startsWith("daughter") && isSingleDaughter) {
    return "daughter";
  }
  return memberCode
    .replace(/_/g, " ")
    .replace(/\b\w/g, match => match.toUpperCase());
};

// returning a hard coded string for single children
// for multiple son or daughter return code along with their number
// use for displaying
const getMemberDisplayName = ({
  memberCode = "self",
  isSingleSon = false,
  isSingleDaughter = false,
}) => {
  if (memberCode.startsWith("son") && isSingleSon) {
    return "Son";
  }
  if (memberCode.startsWith("daughter") && isSingleDaughter) {
    return "Daughter";
  }
  return memberCode
    .replace(/_/g, " ")
    .replace(/\b\w/g, match => match.toUpperCase());
};

// convert SI amount from integer to string in lakhs with on first latter capital
const numToLakhSingleWord = SItoConvert => {
  if (SItoConvert > 9999999) {
    let calculated = Number(SItoConvert) / Number(10000000);
    return `${calculated} C`;
  } else if (SItoConvert > 99999) {
    let calculated = Number(SItoConvert) / Number(100000);
    return `${calculated} L`;
  } else {
    return SItoConvert;
  }
};
// convert SI amount from integer to string in lakhs
const numToLakh = SItoConvert => {
  if (SItoConvert > 9999999) {
    let calculated = Number(SItoConvert) / Number(10000000);
    return `${calculated} ${calculated > 1 ? "Crores" : "Crore"}`;
  } else if (SItoConvert > 99999) {
    let calculated = Number(SItoConvert) / Number(100000);
    return `${calculated} ${calculated > 1 ? "Lakhs" : "Lakh"}`;
  } else {
    return SItoConvert;
  }
};

// Get premium amount with number of years
function getDisplayPremium({ total_premium, tenure }) {
  return `${amount(total_premium)} / ${
    parseInt(tenure) > 1 ? `${tenure} years` : "year"
  }`;
}

// // get Age String
export const getGlobalAgeString = (age, displayFull = false) => {
  if (age >= 1) {
    return !displayFull ? `${age}Y` : `${age} year${age !== 1 && "s"}`;
  } else {
    return !displayFull
      ? `${Math.round(parseFloat(age * 12))}M`
      : `${Math.round(parseFloat(age * 12))} months`;
  }
};

// Check if the string contains integers
const containsNumber = (stringToCheck = "") => {
  // this will pass only if the whole string is of number
  // for eg. "10000" will pass but "5_days"/"1.5_x" will not pass
  return /^\d+$/.test(stringToCheck);
};

export {
  numToLakhSingleWord,
  removeHtmlTagsFromString,
  stringLengthCutter,
  getMemberName,
  getMemberDisplayName,
  amount,
  effectiveStringCutter,
  numToLakh,
  removeSpaceAddUnderscore,
  getDisplayPremium,
  ageStringToYear,
  cutLastNumericValue,
  containsNumber,
};
