import { useState } from "react";
import { useTheme } from "src/customHooks";
// UTILS
import { effectiveStringCutter } from "src/utils/string.utility";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import { RiderDescriptionWrapperStyled } from "./RiderSection/RiderCard/RiderCard.style";

import AdditionalInfoDrawer from "./AdditionalInfoDrawer";

export default function Description({ description, additionalInfo }) {
  const { colors } = useTheme();
  const [showMore, setShowMore] = useState(false);
  const [shouldOpenDrawer, setShouldOpenDrawer] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);
  const handleShowMore = evt => {
    evt.stopPropagation();
    toggleShowMore();
  };

  let contentInsideAnchor = description?.includes("</a>")
    ? description?.match(/<a[^>]*>(.*?)<\/a>/i)[1]
    : "";

  const handleClick = e => {
    if (e.target.tagName === "A") {
      if (e.target.id) {
        setShouldOpenDrawer(true);
      }
    }
  };

  return (
    <>
      <RiderDescriptionWrapperStyled>
        <div>
          {showMore ? (
            description.includes("\r\n") ? (
              description.split("\r\n").map((desc, idx) => (
                <>
                  <FHTypography
                    variant="subheading1"
                    weight="base"
                    colorType="subHeading"
                    key={idx}
                    onClick={e => handleClick(e)}
                    dangerouslySetInnerHTML={{
                      __html: desc,
                    }}
                  />
                  <br />
                </>
              ))
            ) : (
              <FHTypography
                variant="subheading1"
                weight="base"
                colorType="subHeading"
                onClick={e => handleClick(e)}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )
          ) : (
            <FHTypography
              variant="subheading1"
              weight="base"
              colorType="subHeading"
              onClick={e => handleClick(e)}
              dangerouslySetInnerHTML={{
                __html: effectiveStringCutter({
                  string: description,
                  approxLength: 80,
                  endOfStringAs: "dotted",
                }),
              }}
            />
          )}
        </div>
        {description?.length > 80 && (
          <button onClick={handleShowMore}>
            <FHTypography
              variant="subheading1"
              weight="md"
              color={colors.primary_color}
            >
              {showMore ? "Read less" : "Read more"}
            </FHTypography>
          </button>
        )}
      </RiderDescriptionWrapperStyled>

      <AdditionalInfoDrawer
        shouldOpenDrawer={shouldOpenDrawer}
        setShouldOpenDrawer={setShouldOpenDrawer}
        title={contentInsideAnchor}
        additionalInfo={additionalInfo}
      />
    </>
  );
}
