import { createSlice } from "@reduxjs/toolkit";

//===============================================

const initialState = {
  isOnProductDetails: false,
  appLoading: false,
  loadingQuotes: false,
  quotes: [],
  quotesToShare: [],
  pos_popup: false,
  shortlistedQuotes: [],
  shareType: {},
  showSharePopup: true,
  quotesOnCompare: false,
  showEditMembers: false,
  editStep: 1,
  quotesForCompare: [],
  selectedPlan: {},
  createCartData: {},
  cartItems: [],
  filterQuotes: [],
  fetchFilters: [],
  shouldFetchQuotes: false,
  policyTypes: {},
  policyType: "",
  filters: {
    insurers: [],
    premium: null,
    cover: "",
    ownCover: "",
    planType: "",
    multiYear: "",
    basePlanType: "Base Health",
    moreFilters: {},
  },
  productDiscounts: [],
  selectedDiscount: {},
  selectedQuotes: {},
  selectedGroup: "group_code_1",
  selectedRiders: [],
  quotesForSort: {},
  isShortlistTourDone: sessionStorage.getItem("shortlistTourDone"),
  loadedQuotesLength: 0,
  isQuotesBtnClick: false,
};

const quotePageSlice = createSlice({
  initialState,
  name: "quote",
  reducers: {
    setAppLoading: (state, action) => {
      state.appLoading = action.payload;
    },
    setShouldFetchQuotes: (state, action) => {
      state.shouldFetchQuotes = action.payload;
    },

    addShortListedQuote: (state, action) => {
      // Check if the quote is already shortlisted
      const isAlreadyThere = state?.shortlistedQuotes?.find(
        quote =>
          quote.product.id == action.payload.product.id &&
          quote.sum_insured === action.payload.sum_insured &&
          quote.tenure === action.payload.tenure,
      );

      if (isAlreadyThere) return;
      state.shortlistedQuotes = [...state.shortlistedQuotes, action.payload];
    },

    replaceShortlistedQuote: (state, action) => {
      state.shortlistedQuotes = action.payload;
    },

    setPosPopup: (state, action) => {
      state.pos_popup = action.payload;
    },
    setShowEditMembers: (state, action) => {
      state.showEditMembers = action.payload;
    },

    setEditStep: (state, action) => {
      state.editStep = action.payload;
    },
    replaceShareQuotes: (state, action) => {
      state.quotesToShare = action.payload;
    },
    setShareType: (state, action) => {
      state.shareType = action.payload;
    },

    setSelectedRiders: (state, action) => {
      state.selectedRiders = action.payload;
    },

    setLoadingQuotes: (state, action) => {
      state.loadingQuotes = action.payload;
    },

    setSelectedQuotes: (state, action) => {
      state.selectedQuotes = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    updateQuotesForCompare: (state, action) => {
      state.quotesForCompare = action.payload;
    },
    setQuotesForCompare: (state, action) => {
      if (
        state.quotesForCompare.indexOf(action.payload[0]) === -1 &&
        state.quotesForCompare.length < action.payload[1]
      ) {
        state.quotesForCompare = [...state.quotesForCompare, action.payload[0]];
      }
    },
    removeQuotesForCompare: (state, action) => {
      state.quotesForCompare = state.quotesForCompare.filter(
        data => data !== action.payload,
      );
    },

    replaceQuotes: (state, action) => {
      state.quotes = action.payload;
    },

    saveQuotes: (state, action) => {
      state.quotes = [...state.quotes, action.payload];
      state.filterQuotes = [...state.filterQuotes, action.payload];
    },

    clearFilterQuotes: state => {
      state.filterQuotes = [];
      state.quotes = [];
      state.quotesForCompare = [];
      state.quotesOnCompare = false;
    },

    saveProductCart: (state, action) => {
      state.cartItems = action.payload;
    },

    insurerFilterQuotes: (state, action) => {
      state.filterQuotes = state.quotes.map(item => {
        return item.filter(quote => {
          if (action.payload.includes(quote.company_alias)) return quote;
        });
      });
    },

    premiumFilterQuotes: (state, action) => {
      state.filterQuotes = state.quotes.map(item => {
        if (action.payload?.code.includes("<")) {
          return item.filter(quote => quote.premium < 5000);
        } else {
          return item.filter(
            quote =>
              quote.premium > action.payload?.code?.split("-")[0] &&
              quote.premium < action.payload?.code?.split("-")[1],
          );
        }
      });
    },
    setQuotesForSort: (state, action) => {
      state.quotesForSort = action.payload;
    },

    setPolicyTypes: (state, action) => {
      state.policyTypes = action.payload;
    },
    setPolicyType: (state, action) => {
      state.policyTypes = action.payload;
    },
    setIsShortlistTourDone: (state, actions) => {
      state.isShortlistTourDone = actions.payload;
    },
    setLoadedQuotesLength: (state, actions) => {
      state.loadedQuotesLength = actions.payload;
    },
    setQuotesBtnClick: (state, action) => {
      state.isQuotesBtnClick = action.payload;
    },
  },
});

export const {
  setAppLoading,
  setShouldFetchQuotes,
  addShortListedQuote,
  replaceShortlistedQuote,
  setPosPopup,
  setShowEditMembers,
  setQuotesBtnClick,
  setEditStep,
  replaceShareQuotes,
  setShareType,
  setSelectedRiders,
  setLoadingQuotes,
  setSelectedQuotes,
  setFilters,
  updateQuotesForCompare,
  setQuotesForCompare,
  removeQuotesForCompare,
  replaceQuotes,
  saveQuotes,
  clearFilterQuotes,
  saveProductCart,
  insurerFilterQuotes,
  premiumFilterQuotes,
  setQuotesForSort,
  setPolicyTypes,
  setPolicyType,
  setIsShortlistTourDone,
  setLoadedQuotesLength,
} = quotePageSlice.actions;

export const insurerFilter = data => {
  let aliasSet = [];
  data.map(({ alias }) => aliasSet.push(alias));

  return async dispatch => {
    dispatch(insurerFilterQuotes(aliasSet));
  };
};

export const premiumFilterCards = data => {
  const { code } = data;
  return async dispatch => {
    dispatch(premiumFilterQuotes({ code }));
  };
};

export default quotePageSlice.reducer;
