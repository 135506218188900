import { RiLoader4Fill } from "react-icons/ri";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import {
  FLEX_COL,
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  PALETTE,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components";
import useShareModal from "../hooks/useShareModal";
import { SHARE_OPTIONS } from "../shareModule.constant";
import ShareModuleOtherActions from "./ShareModuleOtherActions";
import { VIEW } from "src/styles/responsiveStyle";
import { Fragment, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { CiCircleRemove } from "react-icons/ci";
import { FaTelegramPlane } from "react-icons/fa";
import { Button } from "src/components/Common/Button/ContinueButton";
import { brokerTenant } from "src/utils/tenantSettings";

function ShareModuleFinalStep({ handleCloseShareModule }) {
  const {
    handleChangeShareElementValues,
    shareElementValues,
    shareElementError,
    isSharing,
    shareResponse,
    shareAllResponse,
    handleShareElementClick,
    handleShareAllElementClick,
  } = useShareModal();

  const { settings } = useFrontendBoot();
  const { share_option_visibility } = settings || {};
  const [shareOption, setShareOption] = useState(SHARE_OPTIONS[0]);

  const { colors } = useTheme();
  return (
    <ShareModuleModalContentContainerStyled colors={colors}>
      <div className="share_options_container">
        {SHARE_OPTIONS.map(option => {
          // we have to add ! here
          if (!share_option_visibility.includes(option.label)) return <></>;

          return (
            <div key={option.label} className="share_element_inputContainer">
              <label
                htmlFor={`share_${option.label}`}
                className="share_element_label"
              >
                <section
                  className="share_element_icon"
                  onClick={() => setShareOption(option)}
                >
                  <span
                    data-is-active={
                      option.label === shareOption.label && "active"
                    }
                  >
                    {option.icon}
                  </span>
                </section>
                <FHTypography variant="h6" weight="md">
                  {option.label}
                </FHTypography>
              </label>
            </div>
          );
        })}
      </div>
      <div className="input_container">
        <span>
          <FHTypography variant="subheading1" weight="md">
            {shareOption.label}
          </FHTypography>
        </span>
        <input
          type="text"
          name={shareOption.label}
          id={shareOption.label}
          value={shareElementValues[shareOption.label]}
          onChange={handleChangeShareElementValues}
          autoComplete="off"
          placeholder={shareOption.placeholder}
          maxLength={shareOption.label !== "email" ? 10 : 100}
        />
        <div className="error_Div">
          <FHTypography
            variant="subheading2"
            weight="md"
            type="error"
            className="shareModule_messages"
          >
            {shareElementError[shareOption.label]}
          </FHTypography>
        </div>
      </div>

      {brokerTenant("fyntune") && (
        <ShareAllButton
          primaryColor={colors.primary_color}
          onClick={() =>
            handleShareAllElementClick(SHARE_OPTIONS, share_option_visibility)
          }
        >
          Share to all communication channels
          {Object.keys(isSharing).every(
            status => isSharing[status] === true,
          ) ? (
            <RiLoader4Fill className="rotate" size={20} />
          ) : (
            <FaTelegramPlane size={20} />
          )}
        </ShareAllButton>
      )}
      <ShareModuleOtherActions />

      <div className="buttonContainer">
        <button
          className={`shareBtn ${FONT.subheading1}`}
          onClick={handleCloseShareModule}
        >
          <span>Cancel</span>
        </button>
        <button
          className={`shareBtn ${FONT.subheading1}`}
          onClick={() => handleShareElementClick(shareOption.label)}
          disabled={isSharing[shareOption.label]}
          data-share-button={"share"}
        >
          {
            <Fragment>
              <span>Share</span>
              {isSharing[shareOption.label] && (
                <RiLoader4Fill className="rotate" size={20} />
              )}
            </Fragment>
          }
        </button>
      </div>

      <section className="shareModule_content_foot">
        <FHTypography variant="subheading1" weight="semi" colorType="heading">
          * Please note that the premium may vary in future.
        </FHTypography>
      </section>
      {
        // show error or success response
        shareAllResponse ? (
          <ShareModuleResponseTextStyled type={shareAllResponse}>
            <FHTypography
              variant="h6"
              weight="md"
              className="shareModule_messages"
            >
              {shareAllResponse === "success"
                ? "Shared to all communication channels successfully"
                : "Failed to share over all communication channels!"}
            </FHTypography>
            {shareAllResponse === "success" && <BsCheckCircle size={20} />}
          </ShareModuleResponseTextStyled>
        ) : shareResponse.type ? (
          <ShareModuleResponseTextStyled type={shareResponse.type}>
            {shareResponse.type === "success" ? (
              <FHTypography
                variant="h6"
                weight="md"
                className="shareModule_messages"
              >
                {shareResponse?.message}
              </FHTypography>
            ) : (
              <FHTypography
                variant="h6"
                weight="md"
                className="shareModule_messages"
              >
                Something Went Wrong
              </FHTypography>
            )}

            {shareResponse.type === "success" ? (
              <BsCheckCircle size={20} />
            ) : (
              <CiCircleRemove size={20} />
            )}
          </ShareModuleResponseTextStyled>
        ) : (
          <></>
        )
      }
    </ShareModuleModalContentContainerStyled>
  );
}

const ShareModuleResponseTextStyled = styled.div`
  padding: 1rem 0;
  background-color: ${({ type }) => PALETTE[type].main + "22"};
  color: ${({ type }) => PALETTE[type].main};
  border-radius: ${ROUNDED.base};
  ${FLEX_ROW_CENTER};
  gap: 0.6rem;
`;

const ShareModuleModalContentContainerStyled = styled.div`
  ${FLEX_COL};
  padding: 1rem 1.5rem;
  gap: 0.5rem;

  ${VIEW.md} {
    gap: 1rem;
  }

  .share_element_inputContainer {
    width: 100%;
    ${FLEX_COL};
    gap: 0.5rem;
  }

  .share_element_label {
    overflow: hidden;
    ${FLEX_COL_CENTER};
    width: 100%;

    .share_element_icon {
      padding: 0.3rem;
      position: relative;
      ${FLEX_ROW_CENTER};
      cursor: pointer;
      span {
        ${FLEX_ROW_CENTER};
        width: 2rem;
        height: 2rem;
        border-radius: ${ROUNDED.full};
        color: #cccccc;
        &[data-is-active="active"] {
          color: ${({ colors }) => colors.primary_color};
        }
      }

      ${VIEW.md} {
        padding: 0.5rem;
        span {
          width: 3rem;
          height: 3rem;
          font-size: 3rem;
        }
      }
    }

    & > h6 {
      text-transform: capitalize;
    }
  }

  .shareModule_content_foot {
    padding: 1rem 0.5rem;
    text-align: center;
    background-color: ${SHADES.WHITE};
    border-radius: ${ROUNDED.md};
    ${FLEX_ROW_CENTER};
  }

  .shareModule_messages {
    ::first-letter {
      text-transform: capitalize;
    }
  }
  .share_options_container {
    ${FLEX_ROW}
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .input_container {
    position: relative;
    ${FLEX_ROW}
    border: 2px solid ${SHADES.LIGHT_GRAY};
    border-radius: ${ROUNDED.lg};
    margin-bottom: 0.5rem;
    input {
      width: 100%;
      padding: 0.5rem 0.5rem;
      border: none;
      font-size: 0.9rem;
      font-weight: ${FWEIGHT.md};
      ::placeholder {
        font-size: 0.8rem;
      }
      ${VIEW.xs} {
        flex: 1;
      }

      ${VIEW.md} {
        padding: 0.6rem 0.5rem;
        font-size: 1rem;
        ::placeholder {
          font-size: 0.95rem;
        }
      }
    }

    & > span {
      position: absolute;
      transform: translateY(-50%);
      top: 0px;
      left: 15px;
      text-transform: capitalize;
      background: #fff;
      ${FLEX_ROW}
    }
    .error_Div {
      position: absolute;
      bottom: -0.1rem;
      height: 0px;
    }
  }

  .buttonContainer {
    ${FLEX_ROW_CENTER}
    width: 100%;
    gap: 2rem;
    .shareBtn {
      ${FLEX_ROW_CENTER};
      gap: 0.5rem;
      width: 45%;
      background-color: #fff;
      color: #000;
      border: 1px solid rgb(230, 231, 235);
      &[data-share-button="share"] {
        background-color: ${({ colors }) => colors.primary_color};
        color: ${SHADES.WHITE};
      }
      padding: 0.5rem 1.2rem;
      border-radius: ${ROUNDED.md};
      font-weight: ${FWEIGHT.md};

      ${VIEW.md} {
        min-width: 8rem;
      }

      &:disabled {
        filter: brightness(0.9);
        cursor: not-allowed;
      }
    }
  }
`;

const ShareAllButton = styled(Button)`
  background-color: #fff;
  border: 2px solid ${props => props.primaryColor};

  & h6 {
    ${FLEX_ROW_CENTER};
    gap: 0.4rem;
    color: ${props => props.primaryColor};
  }
  &:hover {
    background-color: ${props => props.primaryColor};
    h6 {
      color: #fff;
    }
  }
`;

export default ShareModuleFinalStep;
