import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import FullScreenLoader from "./Common/FullScreenLoader";
import { useCheckPolicies } from "src/customHooks/useCheckPolicies";
import {
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useQuote, useQuotesCompare } from "src/customHooks";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import { useState, useEffect } from "react";
import { useGetCartQuery } from "src/api/api";

export function AsyncWrapper({ children }) {
  // ------------------------------ Enquiry API Called ------------------------------ //
  const {
    isLoading: isEnquiryLoading,
    isFetching: isEnquiryFetching,
    isUninitialized,
    data: enquiryData,
    isError: isEnquiryError,
  } = useGetEnquiry();

  if (isEnquiryError) {
    sessionStorage.setItem("invalidEnquiry", 1);
    window.location.href = `${
      window.location.origin + process.env.PUBLIC_URL
    }/input/basic-details`;
  }

  // ------------------------------ Enquiry API Called ------------------------------ //

  // ------------------------------ Policy API Called ------------------------------ //
  const {
    doNotCheckPolicies,
    policiesData,
    policiesFetching,
    policiesUninitialized,
    policiesLoading,
    searchQueries,
  } = useCheckPolicies();

  const isThankYouRoute = useRouteMatch({ path: "/thankyou" });

  if (!doNotCheckPolicies) {
    if (policiesData && policiesData?.length > 0) {
      const policies = policiesData;

      const isPaymentSuccess = policies.every(
        policy => policy.payment_status === "success",
      );

      if (isPaymentSuccess && !isThankYouRoute) {
        window.location.replace(
          `${process.env.PUBLIC_URL}/thankyou${window.location.search}`,
        );
      } else {
        <Redirect
          to={{
            pathname: "/proposal_summary",
            search: `enquiryId=${searchQueries?.enquiryId}`,
          }}
        />;
      }
    }
  }

  // ------------------------------ Policy API Called ------------------------------ //

  // ------------------------------ Cart API Called ------------------------------ //
  const { groupCode } = useParams();
  const { buyQuote } = useQuote();
  const { product, enquiryId, riders: ridersString = "" } = useUrlQueries();

  // ========== If riders are selected in compare pdf ===== //
  let decodedString = "";
  try {
    decodedString = decodeURIComponent(ridersString);
  } catch (err) {
    decodedString = "[]";
  }

  let ridersArray = [];
  try {
    ridersArray = JSON.parse(decodedString);
  } catch (err) {
    ridersArray = [];
  }
  // ========== ===================================== ===== //

  const history = useHistory();
  const {
    getCompareQuotes,
    query: { isLoading: isCompareLoading },
  } = useQuotesCompare();
  const [isCartLoading, setIsCartLoading] = useState(true);
  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const isProductDetailsRoute = useRouteMatch({ path: "/productdetails" });
  const isProposalRoute = useRouteMatch({ path: "/proposal" });
  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });

  const makeRequest = [
    isProposalSummaryRoute,
    isProposalRoute,
    isProductDetailsRoute,
    isQuoteRoute,
  ].some(value => value);

  // When PDF continue button is clicked
  useEffect(() => {
    if (product && !isCompareLoading) {
      const compareQuotes = getCompareQuotes(groupCode)?.quotes || [];
      setIsCartLoading(true);
      const productData =
        compareQuotes.find(quoteValue => quoteValue.product.id == product) ||
        {};
      const selectedRiders = ridersArray || [];
      buyQuote(
        {
          total_premium: productData?.total_premium,
          sum_insured: productData?.sum_insured,
          tax_amount: productData?.tax_amount,
          tenure: productData?.tenure,
          product: { id: productData?.product?.id },
          premium: productData?.premium,
          deductible: productData?.deductible,
        },
        selectedRiders,
      )
        .then(() => {
          setIsCartLoading(false);
          window.location.replace(
            `${
              window.location.origin +
              window.location.pathname +
              "?enquiryId=" +
              enquiryId
            }`,
          );
        })
        .catch(err => {
          console.error("err", err);
          setIsCartLoading(false);
        });
      setIsCartLoading(false);
    }
    return () => {};
  }, [isCompareLoading]);

  useEffect(() => {
    if (enquiryData) {
      // when group code mismatched at product details page (invalid group code pass in url)
      const hasGroupCode = enquiryData?.data?.groups?.find(
        group => group.id == groupCode,
      );
      if (!hasGroupCode && isProductDetailsRoute) {
        window?.location?.replace(
          `${
            window.location.origin +
            process.env.PUBLIC_URL +
            `/productdetails/${enquiryData?.data?.groups[0]?.id}` +
            "?enquiryId=" +
            enquiryId
          }`,
        );
      }
    }
  }, [enquiryData]);

  const { isLoading: isCartAPILoading, isError: isCartError } = useGetCartQuery(
    undefined,
    {
      skip: !makeRequest,
    },
  );

  if (isCartError) {
    sessionStorage.setItem("invalidCart", 1);
    history.push("/");
  }

  // ------------------------------ Cart API Called ------------------------------ //

  if (
    isEnquiryLoading ||
    isEnquiryFetching ||
    isUninitialized ||
    policiesLoading ||
    policiesUninitialized ||
    policiesFetching ||
    product ||
    isCartAPILoading ||
    (product && isCartLoading)
  ) {
    return <FullScreenLoader />;
  }

  return children;
}
