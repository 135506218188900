export default function useGetFinanceDetails({
  total_premium,
  downPaymentPercent,
}) {
  // Processing Fee can be ₹300 or 1% of total premium
  const processing_fee =
    total_premium > 30000 ? Math.round(total_premium / 100) : 300;
  const processing_tax = Math.round((processing_fee * 18) / 100);

  const down_payment = Math.round((total_premium * downPaymentPercent) / 100);
  const loan_amount = total_premium - down_payment;
  const total_upfront_payment = down_payment + processing_fee + processing_tax;

  return {
    processing_fee,
    processing_tax,
    loan_amount,
    total_upfront_payment,
    down_payment,
  };
}
