import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetCustomQuotesQuery,
  useLazyGetEnabledProductsQuery,
} from "src/api/api";
import { useQuotesCompare } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { setCustomPlans } from "src/pages/CustomPlanPage/customPlan.slice";
import useCustomPlanTour from "src/pages/CustomPlanPage/hooks/useCustomPlanTour";

export default function useCustomPlanFilter() {
  const dispatch = useDispatch();
  const { journeyType } = useFrontendBoot();
  const { groupCode } = useGetEnquiry();
  const customPlans = useSelector(state => state.customPlanPage.customPlans);
  const [triggerQuotesAPI, { data: customQuotesData = [], error }] =
    useLazyGetCustomQuotesQuery();

  const [triggerAPI, { data: enabledProductsData = [], isFetching }] =
    useLazyGetEnabledProductsQuery();
  const { removeQuote: removeCompareQuote } = useQuotesCompare();

  const [modalState, setModalState] = useState({
    isCompanyModalOpen: false,
    isProductModalOpen: false,
    isDeductibleModalOpen: false,
    isCoverModalOpen: false,
    isTenureModalOpen: false,
  });

  const [appliedCovers, setAppliedCovers] = useState([]);
  const [ICSelected, setICSelected] = useState("");
  const [productSelected, setProductSelected] = useState("");
  const [productID, setProductID] = useState("");
  const [coverSelected, setCoverSelected] = useState("");
  const [tenureSelected, setTenureSelected] = useState("");
  const [deductibleSelected, setDeductibleSelected] = useState("");
  const [companyAlias, setCompanyAlias] = useState("");
  const [premiumAmount, setPremiumAmount] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedCovers, setSelectedCovers] = useState([]);
  const [tenureClicked, setTenureClicked] = useState("");

  const openModal = type => {
    setModalState({
      ...modalState,
      [type]: true,
    });
  };

  const closeModal = type => {
    setModalState({
      ...modalState,
      [type]: false,
    });
  };

  const { handleCustomPlanTourDone } = useCustomPlanTour();

  const handleProductModalOpen = () => {
    if (!ICSelected) return;
    openModal("isProductModalOpen");
  };

  const handleDeductibleModalOpen = () => {
    if (!productSelected) return;
    openModal("isDeductibleModalOpen");
  };

  const handleCoverModalOpen = () => {
    if (journeyType !== "health" ? !deductibleSelected : !productSelected)
      return;
    openModal("isCoverModalOpen");
  };

  const handleTenureModalOpen = () => {
    if (!coverSelected) return;
    openModal("isTenureModalOpen");
  };

  const resetFilter = () => {
    setICSelected("");
    setFilteredPlans([]);
    setProductSelected("");
    setCoverSelected("");
    setTenureSelected("");
    setTenureClicked("");
    setCompanyAlias("");
    setDeductibleSelected("");
    setPremiumAmount(false);
  };

  let premium = [];

  if (error) {
    premium = [];
  } else {
    premium = customQuotesData[0]?.data?.data?.filter(value => {
      if (productID === value?.product?.id) return true;
    });
  }

  useEffect(() => {
    if (customQuotesData.length === 0) return;
    const fetchedPlans = customQuotesData.flatMap(customQuoteData => {
      /*
        quote object doesnot contain keys 
        like cashlessHospitalsCount , networkHospitalUrl and groupCode 
        so adding it after getting data from api call.
      */
      const quotesWithProperData = customQuoteData?.data?.data?.map(
        customQuote => ({
          ...customQuote,
          groupCode: groupCode.toString(),
          cashlessHospitalsCount:
            customQuoteData?.data?.cashless_hospitals_count,
          networkHospitalURL: customQuoteData?.data?.network_hospital_url,
        }),
      );
      return quotesWithProperData;
    });

    if (fetchedPlans.length === 0) {
      toast(
        "Currently selected combination is not available. Please select another plan.",
        {
          position: "top-center",
        },
      );
      resetFilter();
      return;
    }

    const fetchedPlansFilteredBySI = fetchedPlans.filter(plan => {
      if (journeyType === "top_up") {
        return (
          productID === plan?.product?.id &&
          deductibleSelected === plan?.deductible &&
          selectedCovers.includes(+plan.sum_insured)
        );
      } else {
        return (
          productID === plan?.product?.id &&
          selectedCovers.includes(+plan.sum_insured)
        );
      }
    });

    setFilteredPlans(fetchedPlansFilteredBySI);

    return () => {};
  }, [customQuotesData]);

  const products = !isFetching
    ? enabledProductsData?.data?.filter(
        product => product?.insurance_type === journeyType,
      )
    : [];

  const coverAmount =
    journeyType !== "health"
      ? enabledProductsData?.data
          ?.map(product => {
            if (product?.insurance_type == "top_up") {
              return product?.sum_insured_deductibles
                ?.filter(value => {
                  return deductibleSelected == value.deductible;
                })
                .map(value => {
                  return value.sum_insured;
                });
            } else {
              return [];
            }
          })
          .flat(2) || []
      : enabledProductsData?.data?.filter(product => {
          if (product.id == productID) {
            return true;
          }
        }) || [];

  const deductible = !isFetching
    ? enabledProductsData?.data?.filter(product => {
        if (journeyType !== "health") {
          return product?.insurance_type == "top_up";
        }
        return;
      }) || []
    : [];

  const addPlansToCustomList = () => {
    const existingCustomPlanList = customPlans[groupCode] ?? [];
    dispatch(
      setCustomPlans({
        [groupCode]: [...existingCustomPlanList, ...filteredPlans],
      }),
    );
    handleCustomPlanTourDone();
    resetFilter();
  };

  const getCurrentGroupCustomPlan = () => {
    return customPlans[groupCode] ?? [];
  };

  const removeCustomPlan = (gp, quote) => {
    const filterQuotes = customPlans[gp]?.filter(plan => {
      return (
        plan?.product?.id !== quote?.product?.id ||
        plan?.sum_insured != quote?.sum_insured ||
        plan?.tenure !== quote?.tenure
      );
    });

    removeCompareQuote(quote);

    dispatch(
      setCustomPlans({
        [gp]: [...filterQuotes],
      }),
    );
  };
  return {
    appliedCovers,
    setAppliedCovers,
    journeyType,
    modalState,
    openModal,
    closeModal,
    tenureClicked,
    setTenureClicked,
    ICSelected,
    setICSelected,
    productSelected,
    setProductSelected,
    productID,
    setProductID,
    coverSelected,
    setCoverSelected,
    tenureSelected,
    setTenureSelected,
    deductibleSelected,
    setDeductibleSelected,
    companyAlias,
    setCompanyAlias,
    premiumAmount,
    setPremiumAmount,
    selectedCovers,
    setSelectedCovers,
    handleProductModalOpen,
    handleDeductibleModalOpen,
    handleCoverModalOpen,
    handleTenureModalOpen,
    resetFilter,
    premium,
    triggerAPI,
    isFetching,
    triggerQuotesAPI,
    products,
    coverAmount,
    deductible,
    addPlansToCustomList,
    getCurrentGroupCustomPlan,
    removeCustomPlan,
    plansFound: filteredPlans?.length,
  };
}
