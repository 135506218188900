// Tracking events for BCL and Hero

import { brokerAppPhase, brokerTenant } from "src/utils/tenantSettings";
import { useFrontendBoot, useGetEnquiry } from "./useGetCommonQueries";
import { useSelector } from "react-redux";
import { useCart, useCompanies, usePortJourneyConfig } from ".";
import { numToLakh } from "src/utils/string.utility";
import { numberToDigitWord, onWhichPage } from "src/utils/helper";
import { webEngageEncryptUserData } from "src/utils/eventTracking";

export function useEventTracking() {
  // hooks
  const { data, currentGroupObject, enquiryData } = useGetEnquiry();

  const { getCompanyLogo } = useCompanies();
  const { journeyType, settings } = useFrontendBoot();
  const { multiindividual_visibilty } = settings || {};
  const { selectedMembers } = useSelector(state => state.greetingPage);
  const { cartEntries, getCartTotalPremium } = useCart();
  const { isPortPlan } = usePortJourneyConfig();
  const { baseCombinedQuotes } = useSelector(state => state.differentQuotes);

  // variable
  const enquiriesData = data?.data || [];
  let planDetails = [];
  let groupObj = [];
  let totalFamilyGroup = [];
  let proposalPlanDetails = [];

  baseCombinedQuotes.forEach(quote => {
    const detailsObj = {
      "Insurer Name": quote?.[0]?.[0]?.product?.company?.name,
      "Insurer Logo": getCompanyLogo(quote?.[0]?.[0]?.product?.company?.alias),
      Cover: numToLakh(quote?.[0]?.[0]?.sum_insured),
      "Yearly Premium incl GST": quote?.[0]?.[0]?.total_premium,
    };
    planDetails.push(detailsObj);
  });

  // Arranging group wise member
  enquiriesData?.groups?.map((group, index) => {
    if (group.type === "all") return;
    // pushing obj for get all family member length
    totalFamilyGroup.push(group);
    groupObj = [
      ...groupObj,
      {
        [`Groups ${index + 1}`]: group?.members?.join(", "),
      },
    ];
  });

  let dataToSend = {
    members: selectedMembers,
  };
  if (selectedMembers.length === 1) {
    dataToSend.plan_type = "I";
  }
  if (
    (journeyType === "top_up" || +multiindividual_visibilty === 0) &&
    selectedMembers.length > 1
  ) {
    dataToSend.plan_type = "F";
  }

  // get user plan detail for Bajaj webEngage Tracking
  cartEntries?.forEach(cartEntry => {
    const groupObj = {
      "Insurer Logo": getCompanyLogo(cartEntry?.product?.company?.alias),
      Cover: numberToDigitWord(cartEntry?.sum_insured),
    };
    proposalPlanDetails.push(groupObj);
  });

  const planTypeValue = alias => {
    switch (true) {
      case isPortPlan(currentGroupObject?.id): {
        return "Port";
      }
      case journeyType === "health": {
        return "Base Health";
      }
      case journeyType === "top_up": {
        return alias === "liberty_general"
          ? "Super Top-Up"
          : process.env.REACT_APP_TOPUP_TITLE || "Top Up";
      }
      default: {
        return "";
      }
    }
  };

  const eventNameHandler = eventName => {
    return `HI_Portal_${eventName}_${
      journeyType === "health" ? "Health" : "TopUp"
    }`;
  };

  const webEngageTrackEvent = (eventName, pageData = {}) => {
    if (
      brokerTenant("bajaj") &&
      brokerAppPhase("PROD") &&
      !process.env.REACT_APP_BLOCK_JOURNEY
    ) {
      let eventProps = {
        "Trace ID": enquiriesData?.trace_id,
        "Enquiry URL": window.location.href,
        "Family Groups Selected": currentGroupObject?.members?.join(", "),
        "Total Family Group": totalFamilyGroup?.length,
        "Family Groups Name": groupObj,
        "Plan Details": planDetails,
      };

      const proposalPageProps = {
        "Insurer Name": cartEntries
          ?.map(cartEntry => cartEntry?.product?.company?.name)
          .join(", "),
        "Plan Name": cartEntries
          ?.map(cartEntry => cartEntry?.product?.name)
          .join(", "),
        "Total Insurance Cover": numToLakh(
          cartEntries?.reduce((sum, policy) => sum + policy.sum_insured, 0),
        ),
        "Riders Name": cartEntries
          ?.map(item => {
            return item?.[`${journeyType}_riders`]?.length > 0
              ? item?.[`${journeyType}_riders`].map(rider => rider?.name)
              : "Not Opted";
          })
          ?.join(", "),

        "Plan Details": proposalPlanDetails,
        "Total Family Group": totalFamilyGroup?.length,
        "Family Groups Name": groupObj,
      };

      if (eventName === "OTP Initiated" || eventName === "OTP Submitted") {
        window?.webengage?.track(eventName, {
          Platform: "website",
          Stage: "Health Insurance",
        });
        return;
      }

      if (eventName === "Health Insurance Quote Initiated") {
        let inputTrackData = generateInputTrackEventData({
          selectedMembersList: pageData,
          traceId: enquiriesData?.trace_id,
        });
        window?.webengage?.track(eventName, inputTrackData);
        return;
      }
      if (eventName === "Health Insurance Plan Opted") {
        window?.webengage?.track(eventName, {
          PlanSelected: pageData.display_name,
        });

        return;
      }

      if (eventName === "Health Insurance Location Added") {
        window?.webengage?.track(eventName, {
          "City Name": pageData,
        });
        return;
      }

      if (eventName === "Insured Member Medical History") {
        window?.webengage?.track(eventName, pageData);
        return;
      }

      if (eventName === "Health Insurance Cover Selected") {
        window?.webengage?.track(eventName, pageData);
        return;
      }

      if (eventName === "Health Insurance Plans Found") {
        eventProps = {
          ...eventProps,
          ...pageData,
        };
      }
      if (eventName === "Health Plan Proposal Started") {
        const keysToExclude = ["Plan Details"];
        const filteredEventProps = Object.fromEntries(
          Object.entries(eventProps).filter(
            ([key]) => !keysToExclude.includes(key),
          ),
        );
        window?.webengage?.track(eventName, {
          ...filteredEventProps,
          ...pageData,
        });
        return;
      }

      if (eventName === "Proposer Details Submitted") {
        window?.webengage?.track(eventName, {
          ...proposalPageProps,
          ...pageData,
          "Premium incl GST": getCartTotalPremium(),
          "Enquiry URL": window?.location?.href,
          "Trace ID": enquiriesData?.trace_id,
        });
        return;
      }
      if (eventName === "Plan Shortlisted") {
        eventProps = {
          ...eventProps,
          ...pageData,
        };
      }

      if (
        eventName === "Health Plans Compared" ||
        eventName === "Plan Comparison Downloaded" ||
        eventName === "Health Plan Buy Started"
      ) {
        eventProps = {
          ...eventProps,
          ...pageData,
          "Plan Type": journeyType === "health" ? "Base Health" : "Top Up",
          "Premium incl GST": getCartTotalPremium(),
        };
      }

      if (
        eventName === "Health Plan Details Viewed" ||
        eventName === "Health Plans Buy Initiated" ||
        eventName === "Riders Selected In Health" ||
        eventName === "Health Plan Checkout Started"
      ) {
        const keysToExclude = ["Plan Details"];

        // Create a new object without the specified keys
        const filteredEventProps = Object.fromEntries(
          Object.entries(eventProps).filter(
            ([key]) => !keysToExclude.includes(key),
          ),
        );
        window?.webengage?.track(eventName, {
          ...filteredEventProps,
          ...pageData,
        });

        return;
      }

      // proposal page events
      if (eventName === "KYC Details Submitted") {
        window?.webengage?.track(eventName, {
          ...proposalPageProps,
          "Trace ID": enquiriesData?.trace_id,
          "Enquiry URL": window.location.href,
          "Have CKYC":
            cartEntries[0]?.ckyc_mode === "ckyc_number" ? true : false,
          "Premium incl GST": getCartTotalPremium(),
        });
        return;
      }

      if (eventName === "Insured Details Submitted") {
        const insuredPersonDetails = [];

        Object.keys(pageData).forEach((key, index) => {
          const person = pageData[key];
          const title =
            person.title.charAt(0).toUpperCase() + person.title.slice(1);

          const memberObj = {
            [`Insured Person ${index + 1} Title`]: title,
            [`Insured Person ${index + 1} Name`]: person.name,
            [`Insured Person ${index + 1} Date Of Birth`]: person.dob,
            [`Insured Person ${index + 1} Relation`]: key
              .split("_")
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
          };

          insuredPersonDetails.push(memberObj);
        });

        window?.webengage?.track(eventName, {
          "Insured Person Details": insuredPersonDetails,
          ...proposalPageProps,
          "Trace ID": enquiriesData?.trace_id,
          "Enquiry URL": window.location.href,
          "Have CKYC":
            cartEntries[0]?.ckyc_mode === "ckyc_number" ? true : false,
          "Premium incl GST": getCartTotalPremium(),
        });
        return;
      }

      if (
        eventName === "Health Plan Revised Premium Declaration" ||
        eventName === "Health Plan Revised Premium Accepted" ||
        eventName === "Medical Details Submitted" ||
        eventName === "Other Details Submitted"
      ) {
        window?.webengage?.track(eventName, {
          ...proposalPageProps,
          ...pageData,
          "Trace ID": enquiriesData?.trace_id,
          "Enquiry URL": window.location.href,
          "Have CKYC":
            cartEntries[0]?.ckyc_mode === "ckyc_number" ? true : false,
        });

        return;
      }

      if (eventName === "Payment Initiated") {
        window?.webengage?.track(eventName, {
          ...proposalPageProps,
          "Trace ID": enquiriesData?.trace_id,
          "Proposal Summary URL": window.location.href,
          "Total Premium": getCartTotalPremium(),
          "Family Groups Selected": currentGroupObject?.members?.join(", "),
          "Plan Type": planTypeValue(cartEntries?.product?.company?.alias),
        });
        return;
      }

      if (eventName === "Health Plan Purchased Successfully") {
        window?.webengage?.track(eventName, {
          ...proposalPageProps,
          ...pageData,
          "Trace ID": enquiriesData?.trace_id,
          "Enquiry URL": window.location.href,
        });

        return;
      }

      if (eventName === "Custom Health Plan Initiated") {
        window?.webengage?.track(eventName, {
          ...pageData,
          "Family Groups Selected": currentGroupObject?.members?.join(", "),
          "Family Groups Name": groupObj,
          "Total Family Group": totalFamilyGroup?.length,
        });
        return;
      }

      if (eventName === "Custom Health Plan Added") {
        window?.webengage?.track(eventName, {
          ...pageData,
          "Family Groups Selected": currentGroupObject?.members?.join(", "),
          "Family Groups Name": groupObj,
          "Trace ID": enquiriesData?.trace_id,
          "Total Family Group": totalFamilyGroup?.length,
          "Enquiry URL": window.location.href,
        });
        return;
      }
      window?.webengage?.track(eventName, eventProps);
    } else () => {};
  };

  const registerWebEngageUser = async () => {
    if (
      brokerTenant("bajaj") &&
      brokerAppPhase("PROD") &&
      !process.env.REACT_APP_BLOCK_JOURNEY
    ) {
      const encryptedData = await webEngageEncryptUserData(enquiryData?.mobile);
      window?.webengage?.user?.login(encryptedData);
    }
  };

  const PushEventToCt = (eventName, pageData = {}) => {
    if (brokerTenant("hero")) {
      let membersObjWithAge = enquiryData?.input?.members?.reduce(
        (acc, member) => {
          acc[member.code] = member.ageString;
          return acc;
        },
        {},
      );
      try {
        const Policy_Sub_Type =
          enquiryData?.groups[0]?.plan_type === "F"
            ? "FamilyFloater"
            : enquiryData?.groups[0]?.plan_type === "M"
            ? "Multi Individual"
            : "Individual";
        let eventProps = {
          Product_Type: journeyType,
          Memebers_and_Age: JSON.stringify(membersObjWithAge),
          Deductible: enquiryData?.input?.deductible,
          Policy_Type: enquiryData?.type,
          URL: window.location.href,
          Stage: onWhichPage(),
          TraceID: enquiryData?.trace_id,
        };

        let proposalPageProps = {
          Premium_Amount: getCartTotalPremium(),
          Insurance_Company: cartEntries[0]?.product?.company?.name,
          Insurance_Product: cartEntries[0]?.product?.insurance_type?.alias,
          Policy_Sub_Type,
          "Policy tenure": cartEntries[0]?.tenure,
          SumInsured: cartEntries[0]?.sum_insured,
          Company_Name: cartEntries[0]?.product?.company?.name,
        };

        let policyCreatedProps = {
          Policy_Source: "HeroInsurance",
          Seller_Type:
            enquiriesData?.params?.seller_type ||
            enquiriesData?.params?.journey_type,
          Insurance_Company: cartEntries[0]?.product?.company?.name,
          Insurance_Product: cartEntries[0]?.product?.insurance_type?.alias,
          Deductible: enquiryData?.input?.deductible,
          Policy_Type: enquiryData?.type,
          Policy_Sub_Type,
          SumInsured: cartEntries[0]?.sum_insured,
          Company_Name: cartEntries[0]?.product?.company?.name,
        };

        let updatedEventName = eventNameHandler(eventName);

        if (eventName === "Quotation_Page_Landed") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...eventProps,
            ...pageData,
          });
        }
        if (eventName === "Proposal_Page_Landed") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...eventProps,
            ...proposalPageProps,
            ...pageData,
          });
        }
        if (eventName === "KYC Completed") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...eventProps,
            ...proposalPageProps,
            ...pageData,
          });
        }
        if (eventName === "Payment_Stage") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...eventProps,
            ...proposalPageProps,
            ...pageData,
          });
        }
        if (eventName === "Payment_Initiated") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...eventProps,
            ...proposalPageProps,
            ...pageData,
          });
        }
        if (eventName === "Policy_Created") {
          window?.clevertap?.event?.push(updatedEventName, {
            ...policyCreatedProps,
            ...pageData,
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else () => {};
  };
  return {
    webEngageTrackEvent,
    registerWebEngageUser,
    PushEventToCt,
  };
}

export const generateInputTrackEventData = ({
  selectedMembersList,
  traceId,
}) => {
  const selectedMembers = selectedMembersList?.members;
  const isMemberSelected = memberCode => {
    return (
      selectedMembers?.find(member => member?.code === memberCode) || false
    );
  };

  const memberAge = memberCode => {
    return (
      Number(
        selectedMembers?.find(member => member?.code === memberCode)?.age,
      ) || 0
    );
  };

  const numberOfSons = selectedMembers?.filter(member =>
    member?.code?.startsWith("son"),
  )?.length;

  const numberOfDaughters = selectedMembers?.filter(member =>
    member?.code?.startsWith("daughter"),
  )?.length;

  const eventTrackingData = {
    "Trace ID": traceId,
    "Enquiry URL": window.location.href,

    "Self Selected": !!isMemberSelected("self"),
    "Self Age in Years": memberAge("self"),
    "Spouse Selected": !!isMemberSelected("spouse"),
    "Spouse Age in Years": memberAge("spouse"),

    "Son Selected": !!isMemberSelected("son"),
    "Number of Sons": numberOfSons,
    "Son 1 Age": memberAge("son"),
    "Son 2 Age": memberAge("son2"),
    "Son 3 Age": memberAge("son3"),
    "Son 4 Age": memberAge("son4"),
    "Son 5 Age": memberAge("son5"),

    "Daughter Selected": !!isMemberSelected("daughter"),
    "Number of Daughters": numberOfDaughters,
    "Daughter 1 Age": memberAge("daughter"),
    "Daughter 2 Age": memberAge("daughter2"),
    "Daughter 3 Age": memberAge("daughter3"),
    "Daughter 4 Age": memberAge("daughter4"),
    "Daughter 5 Age": memberAge("daughter5"),

    "Father Selected": !!isMemberSelected("father"),
    "Father Age in Years": memberAge("father"),

    "Mother Selected": !!isMemberSelected("mother"),
    "Mother Age in Years": memberAge("mother"),

    "Grand Father Selected": !!isMemberSelected("grand-father"),
    "Grand Father Age in Years": memberAge("grand-father"),

    "Grand Mother Selected": !!isMemberSelected("grand-mother"),
    "Grand Mother Age in Years": memberAge("grand-mother"),

    "Father-in-Law Selected": !!isMemberSelected("father-in-law"),
    "Father-in-Law Age in Years": memberAge("father-in-law"),

    "Mother-in-Law Selected": !!isMemberSelected("mother-in-law"),
    "Mother-in-Law Age in Years": memberAge("mother-in-law"),

    "Brother Selected": !!isMemberSelected("brother"),
    "Brother Age in Years": memberAge("brother"),

    "Sister Selected": !!isMemberSelected("sister"),
    "Sister Age in Years": memberAge("sister"),

    "Son-in-law Selected": !!isMemberSelected("son-in-law"),
    "Son-in-law Age in Years": memberAge("son-in-law"),
  };
  return eventTrackingData;
};
