import { useState } from "react";
import { RiFileCopy2Line } from "react-icons/ri";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_COL,
  FLEX_ROW_CENTER,
  SHADES,
  ROUNDED,
  FWEIGHT,
} from "src/styles/styleGuide";
import { stringLengthCutter } from "src/utils/string.utility";
import styled from "styled-components";

function ShareModuleOtherActions() {
  const { settings } = useFrontendBoot();
  const { share_option_visibility } = settings || {};

  // STATE/s
  const [copyText, setCopyText] = useState(false);

  //FUNCTION/s
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(String(window.location.href));
      setCopyText(true);
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    } catch (err) {
      setCopyText(false);
    }
  };
  return (
    <ShareModuleOtherActionContainerStyled>
      {share_option_visibility.includes("copy_to_clipboard") && (
        <div className="input_container">
          <input
            value={stringLengthCutter(window.location.href, 45)}
            disabled={true}
          />

          <button onClick={copyLink} className="actionButton">
            <span className="actionButton_text">
              {copyText ? "Copied" : "Copy URL"}
            </span>
            <RiFileCopy2Line size={20} />
          </button>
        </div>
      )}
    </ShareModuleOtherActionContainerStyled>
  );
}

const ShareModuleOtherActionContainerStyled = styled.div`
  width: 100%;
  ${FLEX_COL};
  gap: 0.5rem;

  ${VIEW.md} {
    ${FLEX_ROW_CENTER};
    gap: 2rem;
  }

  .actionButton {
    padding: 0.3rem;
    background: #fff;
    border: 2px solid rgba(230, 231, 235, 0.8);
    border-radius: ${ROUNDED.md};
    white-space: nowrap;
    &_text {
      margin-right: 0.8rem;
      font-size: 0.85rem;
    }
  }
  .input_container {
    position: relative;
    background: rgb(230, 231, 235);
    ${FLEX_ROW_CENTER}
    border: 2px solid ${SHADES.LIGHT_GRAY};
    border-radius: ${ROUNDED.lg};
    width: 100%;
    input {
      width: 70%;
      padding: 0.5rem 0.5rem;
      border: none;
      font-size: 0.85rem;
      font-weight: ${FWEIGHT.md};
      ${VIEW.xs} {
        flex: 1;
      }

      ${VIEW.md} {
        padding: 0.6rem 0.5rem;
        font-size: 0.9rem;
      }
    }
  }
`;

export default ShareModuleOtherActions;
