import aditya from "./aditya.png";
import aegon_life from "./aegon_life.png";
import bajaj_allianz from "./bajaj_allianz.jpeg";
import care from "./Care.png";
import chola_ms_logo from "./Chola logo.png";
import CompanyLogo from "./companyLogo.png";
import digit from "./digit.png";
import zuno from "./zuno.png";
import future_generl from "./FG logo.png";
import fyntune from "./fyntune.png";
import hdfc from "./hdfc.png";
import icici_lombard from "./icici_lombard-min.png";
import kotak_g from "./KGI logo.png";
import liberty_general from "./liberty_general.jpeg";
import manipal from "./manipal.png";
import max_bupa from "./Maxbupa.png";
import SriyahLogo from "./Namma_Cover_Logo.png";
import national_insurance from "./nic logo.png";
import new_india_assurance from "./new_india_assurance.png";
import pinc from "./pinc.png";
import raheja_log from "./Raheja logo.png";
import reliance_group from "./Reliance logo.png";
import renew_buy from "./renew_buy.png";
import royalSunderLogo from "./royal-sundaram-logo.png";
import sbi_g from "./sbi_general.png";
import star from "./Star.png";
import tata from "./tata-aig.png";
import universal_sompo from "./universal_sompo.png";
import iffco_tokio from "./iffco_tokio.png";
import analah from "./analah-logo.png";
import bimaplanner from "./bima-planner.png";
import choice from "./Choice.png";
import uib from "./UIB_logo.jpg";
import bajajCapital from "./BajajCapitalInsurance.png";
import sib_logo from "./SIB_logo.png";
import policyera from "./policyera.png";
import spaLogo from "./spa_logo.png";
import shreeLogo from "./shree_logo.png";
import tata_rightPolicy from "./TATA_RightPolicy.gif";
import tmibasl_footer from "./tmibasl_footer.png";
import radical from "./radical.jpg";
import hero from "./hero.png";
import prudentialLogo from "./prudential_logo.png";
import vcare from "./vcare_logo.png";

const images = {
  prudential: prudentialLogo,
  go_digit: digit,
  max_bupa: max_bupa,
  care_health: care,
  star: star,
  tata_aig: tata,
  aditya_birla: aditya,
  manipal_cigna: manipal,
  hdfc_ergo: hdfc,
  sbi_general: sbi_g,
  kotak_mahindra: kotak_g,
  raheja_qbe_general: raheja_log,
  future_generali: future_generl,
  reliance_general: reliance_group,
  edelweiss_general: zuno,
  cholmandalam_ms: chola_ms_logo,
  royal_sundaram: royalSunderLogo,
  sriyah: SriyahLogo,
  bajaj: bajajCapital,
  sib: sib_logo,
  spa: spaLogo,
  shree: shreeLogo,
  tmibasl: tata_rightPolicy,
  tmibasl_footer: tmibasl_footer,
  iffco_tokio,
  aegon_life,
  bajaj_allianz,
  liberty_general,
  national_insurance,
  new_india_assurance,
  universal_sompo,
  icici_lombard,
  fyntune,
  renew_buy,
  policyera,
  pinc,
  analah,
  bimaplanner,
  choice,
  uib,
  CompanyLogo,
  radical,
  hero,
  vcare,
};

export { images };
