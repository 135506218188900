import { ROUNDED, SHADES } from "src/styles/styleGuide";
import styled from "styled-components/macro";

export const HeaderCardStyled = styled.div`
  margin: 10px 0;
  max-width: 540px;
  width: 100%;
  padding: 2rem 1.5rem;
  border-radius: 0;
  position: relative;
  background: ${SHADES.LIGHT_GRAY}66;
  backdrop-filter: blur(2px);
  border-radius: ${ROUNDED.lg};

  @media (max-width: 1200px) {
    width: 500px;
  }
  @media (max-width: 965px) {
    width: 98%;
    margin: 0 auto;
  }
  @media (max-width: 885px) {
    margin: 0 auto;
    width: 96%;
  }
  @media (max-width: 835px) {
    margin: 20px auto;
    width: 96%;
    h1 {
      font-size: 22px;
      font-weight: 600;
    }
  }
  & > div > div {
    margin-bottom: 1rem;
  }
`;

export const InputPlanCardStyled = styled.div`
  & h3 {
    color: ${props => props.primaryColor};
    background-color: ${props => props.primaryShade};
    border: 1px solid ${props => props.primaryColor};
    border-radius: 15px;
    width: max-content;
    font-size: 24px;
    padding: 3px 9px;
    margin-bottom: 13px;
    text-transform: uppercase;
  }
  & h1 {
    margin-bottom: 37px;
    ${props => {
      return (
        props.settings?.color_visibilty?.gradients &&
        (props.tenantAlias === "tmibasl" || props.tenantAlias === "fyntune") &&
        `background: linear-gradient(to right, ${Object.keys(
          props.settings?.color_visibilty?.gradients,
        )?.map(color => {
          return props.settings?.color_visibilty?.gradients[color];
        })});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
      );
    }}
  }
  & svg {
    color: ${props => props.primaryColor};
    width: 23px;
    height: 22px;
  }
`;

export const ContentBanner = styled.div`
  p {
    font-size: 2rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    ${props => {
      return props.settings?.color_visibilty?.gradients &&
        (props.tenantAlias === "tmibasl" || props.tenantAlias === "fyntune")
        ? `background: linear-gradient(to right, ${Object.keys(
            props.settings?.color_visibilty?.gradients,
          )?.map(color => {
            return props.settings?.color_visibilty?.gradients[color];
          })});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
        : `color: #000000;`;
    }}
    b {
      font-weight: 500;
    }
  }

  li {
    position: relative;
    margin-top: 10px;
    font-size: 14px;
    ${props => {
      return props.settings?.color_visibilty?.gradients &&
        (props.tenantAlias === "tmibasl" || props.tenantAlias === "fyntune")
        ? `background: linear-gradient(to right, ${Object.keys(
            props.settings?.color_visibilty?.gradients,
          )?.map(color => {
            return props.settings?.color_visibilty?.gradients[color];
          })});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
        : `color: #000000;`;
    }}

    &::before {
      display: inline-block;
      content: "✓";
      margin-right: 10px;
      color: white;
      background-color: ${props =>  props.primaryColor};
      -webkit-text-fill-color: white;
      font-size: 11px;
      width: 18px;
      height: 18px;
      text-align: center;
      border-radius: 50%;
      padding-top: 1px;
    }
  }
`;

// Styles for plans list
export const PlanListItemStyled = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 7px !important;
  & h6 {
    ${props =>
      props.settings?.color_visibilty?.gradients &&
      (props.tenantAlias === "tmibasl" || props.tenantAlias === "fyntune") &&
      `background: linear-gradient(to right, ${Object.keys(
        props.settings?.color_visibilty?.gradients,
      )?.map(color => {
        return props.settings?.color_visibilty?.gradients[color];
      })});
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;`};
  }
`;
