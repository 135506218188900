import {
  MdOutlineTextsms,
  MdOutlineMailOutline,
  MdOutlineWhatsapp,
} from "react-icons/md";
// Inputs for share module
const SHARE_OPTIONS = [
  {
    label: "email",
    placeholder: "Enter your Email...",
    icon: <MdOutlineMailOutline size={"100%"} />,
  },

  {
    label: "whatsapp",
    placeholder: "Enter your WhatsApp No...",
    icon: <MdOutlineWhatsapp size={"100%"} />,
  },
  {
    label: "sms",
    placeholder: "Enter your Mobile No...",
    icon: <MdOutlineTextsms size={"100%"} />,
  },
];

// Canvas header heading text array
const CANVAS_HEADER_HEADING_TEXT = ["Insurer", "Premium", "Cover", "Proceed"];

// Share module stages
const SHARE_MODULE_STAGES = {
  quote: "QUOTE",
  shortlisted: "SHORTLISTED_QUOTES",
  compare: "COMPARE",
  proposal: "PROPOSAL",
  summary: "PROPOSAL_SUMMARY",
  product: "RENEWAL_PRODUCT_DETAILS",
};

export { SHARE_OPTIONS, CANVAS_HEADER_HEADING_TEXT, SHARE_MODULE_STAGES };
