import { RiArrowDownSLine } from "react-icons/ri";
import { riderPremiumValue, useTheme, useToggle } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { FLEX_ROW_CENTER, ROUNDED } from "src/styles/styleGuide";
import Spinner from "src/components/Common/Spinner";
import styled from "styled-components";
import { VIEW } from "src/styles/responsiveStyle";

export function CartDetailRow({ title, value, secondaryColor }) {
  return (
    <CardDetailRowWrapperStyled>
      <CardDetailRowTitleStyled secondaryColor={secondaryColor}>
        {title}
      </CardDetailRowTitleStyled>
      <CardDetailRowValueStyled>{value}</CardDetailRowValueStyled>
    </CardDetailRowWrapperStyled>
  );
}

const CardDetailRowWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${VIEW.md} {
    margin-bottom: 4px;
  }
`;

const CardDetailRowTitleStyled = styled(FHTypography).attrs(() => ({
  variant: "body1",
  weight: "base",
  colorType: "body",
}))`
  color: ${props => props.secondaryColor};
`;

const CardDetailRowValueStyled = styled(FHTypography).attrs(() => ({
  variant: "body1",
  weight: "bold",
  colorType: "black",
}))`
  min-width: 80px;
  text-align: right;
  @media (max-width: 768px) {
    line-height: 14px;
    margin-top: 7px;
  }
`;

export function UnavailableMessage({ message = "" }) {
  const { colors } = useTheme();

  return (
    <UnavailableMessageStyled secondary_shade={colors.secondary_shade}>
      {message}
    </UnavailableMessageStyled>
  );
}

const UnavailableMessageStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "md",
}))`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: ${ROUNDED.md};
  background-color: ${props => props.secondary_shade};
`;

export function CartSection({ title = "", children }) {
  const { colors } = useTheme();
  return (
    <CollapseCartContainerStyled>
      {title && (
        <FHTypography
          variant="subheading2"
          weight="semi"
          color={colors.primary_color}
        >
          {title}
        </FHTypography>
      )}
      <div>{children}</div>
    </CollapseCartContainerStyled>
  );
}

export function RiderDetails({ rider, ...props }) {
  return (
    <CartDetailRow
      title={rider?.name}
      value={riderPremiumValue({ rider })}
      {...props}
    />
  );
}

export const plantypes = {
  M: "Multi Individual",
  I: "Individual",
  F: "Family Floater",
};

export function CollapseCartSection({
  initialState = false,
  title,
  children,
  contentLoading = false,
  length = 0,
}) {
  const cartSection = useToggle(initialState);

  const { colors } = useTheme();
  return (
    <CollapseCartContainerStyled>
      <CartHeaderWrapperStyled colors={colors}>
        <FHTypography
          variant="subheading2"
          weight="semi"
          color={colors.primary_color}
        >
          {title}
          {!cartSection.isOn && contentLoading ? (
            <Spinner color={colors.primary_color} size={18} />
          ) : length ? (
            <LenghtStyled>
              {" "}
              ({length} {title?.includes("Discounts") ? "Applied" : "Selected"}
              ):
            </LenghtStyled>
          ) : (
            <></>
          )}
        </FHTypography>
        <CollapseCartButton
          colors={colors}
          data-cart-open={cartSection.isOn}
          onClick={cartSection.toggle}
        >
          <RiArrowDownSLine />
        </CollapseCartButton>
      </CartHeaderWrapperStyled>

      <CollapseCartBodyStyled data-cart-open={cartSection.isOn}>
        <div>{contentLoading ? <LineStyled /> : children}</div>
      </CollapseCartBodyStyled>
    </CollapseCartContainerStyled>
  );
}

const CollapseCartContainerStyled = styled.div`
  padding-block: 0.5rem;
`;

const CollapseCartBodyStyled = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 320ms ease-out;
  transform-origin: top;

  & > div {
    overflow: hidden;
  }

  &[data-cart-open="true"] {
    margin-top: 1rem;
    grid-template-rows: 1fr;
  }
`;

const CollapseCartButton = styled.button`
  ${FLEX_ROW_CENTER}
  width: 1.5rem;
  height: 1.5rem;
  border-radius: ${ROUNDED.full};
  background-color: ${props => props.colors.primary_shade};

  svg {
    font-weight: 700;
    font-size: 1.5rem;
    color: ${props => props.colors.primary_color};
    transition: rotate 320ms ease-in-out;
  }
  &[data-cart-open="true"] {
    svg {
      rotate: 180deg;
    }
  }
`;

const CartHeaderWrapperStyled = styled.div`
  ${FLEX_ROW_CENTER}
  justify-content: space-between;
`;

const LineStyled = styled.div`
  width: 100%;
  min-height: 4rem;

  border-radius: ${ROUNDED.base};
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.5) 50%,
      hsla(0, 0%, 100%, 0) 80%
    ),
    #f1f1f1;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: Animation 2s ease alternate infinite;

  @keyframes Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`;

const LenghtStyled = styled.p`
  display: inline;
  font-size: 11px;
`;
