import { envAppBaseURL } from "src/utils/tenantSettings";

const singlePaymentFunction = id => {
  const formActionUrl = `${envAppBaseURL()}payments`;
  if (id && formActionUrl != "") {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = formActionUrl;
    form.style.display = "none";
    const input = document.createElement("input");
    input.name = "proposal_id";
    input.value = id;
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
};

function getRiderDisabledMsg({
  rider,
  // selectedFeatureOptions = {},
  allRiders,
}) {
  const {
    disable = {},
    is_mandatory = false,
    is_rider_default,
    not_available_with_feature_option,
  } = rider;

  // const selectedFeatureKeys = Object.keys(selectedFeatureOptions)?.map(
  //   feature => feature?.split("_").slice(2).join(" "),
  // );

  const disabledRiderKeys = Object.keys(disable)
    ?.filter(key => disable[key] === true)
    .toString()
    .split("_")
    .slice(3)
    .join("_");

  const notAvailableFeatures = not_available_with_feature_option?.map(
    eachFeature =>
      eachFeature
        .split("_")
        .map(
          eachWords => eachWords.charAt(0).toUpperCase() + eachWords.slice(1),
        )
        ?.join(" "),
  );

  const disabledBy = allRiders
    ?.filter(
      singleRider =>
        rider?.[disabledRiderKeys]?.includes(singleRider?.alias) ?? "",
    )
    ?.map(singleRider => singleRider?.name)
    .join(", ");

  if (is_mandatory) return "This rider is mandatory";
  if (is_rider_default) return "This rider is included by default";
  if (notAvailableFeatures)
    return `This rider is not available with ${notAvailableFeatures}`;
  const riderDisabledMessage = `This rider is ${disabledRiderKeys
    .split("_")
    .join(" ")} ${disabledBy}`;

  return riderDisabledMessage;
}

function getFeatureDisabledMsg({ feature, feature_options, allRiders }) {
  feature_options = feature_options?.filter(
    feature => feature?.isSelected || feature?.is_mandatory,
  );

  if (feature?.is_mandatory) {
    return "This feature is madatory";
  }

  const disableRiderKeys = allRiders
    ?.filter(
      rider =>
        feature?.not_available_with_rider?.includes(rider.alias) &&
        rider.isSelected === true,
    )
    ?.map(rider => rider.name)
    .join(", ");

  const { disable } = feature;
  const disableFeatureKeys = Object.keys(disable)
    .filter(key => disable?.[key] === true)
    .toString()
    .split("_")
    .slice(3)
    .join("_");

  const disableBy = feature_options
    ?.filter(singlefeature => {
      return (
        (feature?.[disableFeatureKeys]?.includes(singlefeature?.alias) ||
          Object.values(singlefeature?.options)?.some(eachOption =>
            Object.keys(eachOption)?.some(
              eachOptionKey =>
                eachOptionKey === feature?.[disableFeatureKeys]?.[0] &&
                eachOptionKey,
            ),
          )) ??
        ""
      );
    })
    ?.map(feature => feature.name)
    ?.join(", ");

  const featureDisabledMessage = `This Feature is ${disableFeatureKeys
    ?.split("_")
    .join(" ")} ${disableBy} ${disableRiderKeys ?? ""}`;

  return featureDisabledMessage;
}

export { singlePaymentFunction, getRiderDisabledMsg, getFeatureDisabledMsg };
